query getStudents {
	getStudents {
		studentID
		bookingsTotal
		hasSignedWaiver
		bookingsTotalPaymentMethodFree
		bookingsTotalPaymentMethodCard
		bookingsTotalPaymentMethodCash
		details {
			detailsID
			firstName
			lastName
			nickName
			emailAddress
			mobilePhoneNumber
			instagramUsername
		}
		bookingsTotal
		bookings {
			bookingID
			notes
			createdAt
			bookingQuantity
			equipmentQuantity
			paymentMethod
			cost
			isCheckedIn
			isCancelled
			session {
				sessionID
				title
				startTime
				endTime
			}
		}
	}
	getGrossTotal
	getStudentsTotal
	getBookingsTotal
}
